import React from "react";
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Navbar from './global-components/navbar-v2';
import GalleryGrid from "./section-components/gallery";
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';

const Gallery = () => {

    const { t, i18n } = useTranslation();


    return (
        <>
            <MetaTags>
                <title>Pansion Franka - Gallery</title>
                <meta name="Pansion Franka - Galerie" content="Galerieseite der Pansion Franka" />
                <meta property="og:title" content="Pansion Franka - Galerie" />
            </MetaTags>
            <Navbar />
{/*             <PageHeader headertitle={t('gallery')} subheader={t('gallery')} />
 */}            <GalleryGrid />
            <CallToActionV1 />
            <Footer />

        </>
    )

}

export default Gallery