import React from "react";
import Availability from "./section-components/availability";
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Navbar from './global-components/navbar-v2';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';



const Prices = () => {


    const { t, i18n } = useTranslation();


    return (
        <div>
            <MetaTags>
            <title>Pansion Franka - Prices</title>
            <meta name="Pansion Franka - Preise" content="Preisseite der Pansion Franka" />
            <meta property="og:title" content="Pansion Franka - Preise" />
          </MetaTags>
            <Navbar/>
{/*             <PageHeader headertitle={t('prices')} subheader={t('prices')} />
 */}            <Availability />
            <CallToActionV1 />
            <Footer />
        </div>
    )

}

export default Prices;