import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import Aboutv3 from './section-components/about-v3';
import ProductSlider from './section-components/product-slider-v2';
import Testimonial from './section-components/testimonial-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import MetaTags from 'react-meta-tags';


const Home_V2 = () => {
    return <div>
        <MetaTags>
            <title>Pansion Franka - Home</title>
            <meta name="Pansion Franka - Startseite" content="Startseite der Pansion Franka
" />            <meta property="og:title" content="Pansion Franka - Startseite" />
          </MetaTags>
        <Navbar />
        <Banner />
        <Aboutv3 />
        <ProductSlider />
        <Testimonial />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V2

