import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';



const ContactV1 = () => {

    const { t, i18n } = useTranslation();


    return <div>
        <MetaTags>
            <title>Pansion Franka - Contact</title>
            <meta name="Pansion Franka - Kontakt" content="Kontaktseite der Pansion Franka" />
            <meta property="og:title" content="Pansion Franka - Kontakt" />
        </MetaTags>
        <Navbar />
{/*         <PageHeader headertitle={t('contactUs')} subheader={t('contactUs')} />
 */}        <ContactInfo />
        <ContactForm />
        <Map />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ContactV1

